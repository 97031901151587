import React from 'react'
import styled from 'styled-components'
import logoGradient from 'img/logo/logo-gradient.png'
import { pallete } from 'services/theme'
import { Link, FlexBox, Anchor } from 'components/Core'
import { typography } from 'services/theme'

const title = 'ASHLEY HAY DESIGN'
const logoSize = '108.667px'

const Nav = styled.nav`
  width: 100%;
  display: flex;
  margin-top: 2rem;
  margin-bottom: 2rem;
`

const ImageLink = styled(Link)`
  width: ${logoSize};
  height: ${logoSize};
  display: block;
`

const Logo = styled.img`
  width: ${logoSize};
  height: ${logoSize};
`

const Text = styled.div`
  font-size: ${typography.title2.size};
  margin: auto;
  text-align: center;
  @media (max-width: 503px) {
    font-size: ${typography.subtitle1.size};
  }
  @media (max-width: 419px) {
    font-size: ${typography.subtitle2.size};
  }
`

const ShopLink = styled(Anchor)`
  width: 72px;
  height: 40px;
  display: flex;
  margin: auto;
  background-color: black;
  color: white;
  &:hover {
    background-color: ${pallete.pink.main};
  }
`

const LinkContainer = styled.div`
  display: flex;
  width: ${logoSize};
  height: ${logoSize};
  min-width: ${logoSize};
`

const LinkText = styled.div`
  text-align:center;
  margin: auto; 
  font-size: 15px;
  line-height: 15px;
  height: 11px;
`

export default function Header ({ backTo }) {
  
  return (
    <Nav>
      <ImageLink to={backTo}>
        <Logo src={logoGradient} alt="logo" />
      </ImageLink>
      <FlexBox>
        <Text>{title}</Text>
      </FlexBox>
      <LinkContainer>
        <ShopLink
          href="http://ashleyhaydesign.com"
          target="_blank"
          rel="noopener noreferrer" 
        >
          <LinkText>SHOP</LinkText>
        </ShopLink>
      </LinkContainer>
    </Nav>
  )
}
