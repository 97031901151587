import React from "react"
import SEO from 'components/SEO'
import CssBaseline from 'components/CssBaseline'
import Header from 'components/Header'
import { Typography, Spacer } from 'components/Core'

export default function NotFound() {
  return (
    <CssBaseline grid>
      <SEO title="NOT FOUND" />
      <div className="container">
        <Header backTo="/" />
        <Spacer size="2" />
        <Typography variant="title1">Looks like you are lost</Typography>
      </div>
    </CssBaseline>
  )
}
